<template>
    <div class="flex flex-wrap items-center justify-between gap-x-4 gap-y-8">
        <UiPaginationInfo
            :t="rangeTranslation"
            :pagination="pagination"
        />

        <UiSelect
            v-if="!hidePerPage"
            v-model="selected"
            :label="label"
            :options="selectOptions"
            @chante="emit('change', $event)"
        />
    </div>
</template>

<script lang="ts" setup generic="T">
import type { SelectOption } from '@core/app/components/core/ui/form/CoreUiFormSelect.vue'
import type { ApiResponse } from '@composable-api/api.response'

const {
    pagination,
    rangeTranslation = 'filters.records_range',
    selectLabel,
    options,
    hidePerPage = false,
} = defineProps<{
    pagination: InstanceType<typeof ProductFiltersPaginationModel> | ReturnType<ApiResponse<any>['getMeta']> | null | undefined
    rangeTranslation?: string
    selectLabel?: string
    options?: SelectOption<T>[]
    hidePerPage?: boolean
}>()

const model = defineModel<SelectOption<T>['value'] | SelectOption<number>['value'] | { perPage: number }>()

const selected = computed({
    get() {
        if (typeof model.value === 'object' && model.value && 'perPage' in model.value) {
            return model.value.perPage as number
        }
        return model.value
    },
    set(val) {
        if (typeof model.value === 'object' && model.value && 'perPage' in model.value) {
            if (typeof val !== 'number') {
                return
            }
            model.value.perPage = val
        } else {
            model.value = val
        }
    },
})

const { t } = useI18n()
const label = computed(() => selectLabel ?? `${t('filters.items_per_page')}:`)

const selectOptions = computed<SelectOption<T>[] | SelectOption<number>[]>(() => {
    return options ?? [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
    ]
})

const emit = defineEmits<{
    change: [val: SelectOption<T>['value'] | SelectOption<number>['value']]
}>()

</script>

<style lang="scss" scoped>

</style>
